import React from "react";

import { StarFilledIcon, BarChartIcon } from "@radix-ui/react-icons";

const ProductTitle = ({ productName, productTagline }: any) => {
  return (
    <>
      <div className="flex items-center gap-3">
        {productName === "Pro" ? (
          <StarFilledIcon width={30} height={30} className="text-main" />
        ) : (
          <BarChartIcon width={30} height={30} className="text-main" />
        )}
        <h4
          id={productName}
          className="text-3xl leading-8 text-left font-semibold text-mainDark first-letter:uppercase"
        >
          {productName}
        </h4>
      </div>

      <p className="mt-3">{productTagline}</p>
    </>
  );
};

export default ProductTitle;
