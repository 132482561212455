"use client";
import { Button } from "@components/ui/button";
import useSubscriptionData from "@hooks/useSubscriptionData";
import ProductFeatures from "./ProductFeatures";
import ProductTitle from "./ProductTitle";
import ProductPrice from "./ProductPrice";
import { useSession, signIn } from "next-auth/react";
import { useUserInput } from "@providers/UserInputProvider";
import { getProductId } from "@app/api/v1/stripe/prices/priceMapping";
import { CheckIcon } from "lucide-react";
export const planIdParameterName = "planId";

interface Price {
  id: string; // Added id property here
  currency: string;
  unitAmount: number;
  interval: string;
}

const PricingCard = ({
  productName,
  productTagline,
  price,
  disabled,
}: {
  productName: string;
  productTagline: string;
  price: Price; // Using the updated Price interface
  disabled?: boolean;
}) => {
  const { subscriptionData } = useUserInput() as any;
  const activeSubscription = subscriptionData?.status === "active";
  const userProductId = getProductId(subscriptionData?.priceKey);
  const { handleNewSubscription } = useSubscriptionData();
  const { data: session } = useSession();
  const planId = price.id;

  const handleNewSubscriptionClick = () => {
    if (session) {
      handleNewSubscription(planId);
    } else {
      signIn(undefined, {
        callbackUrl: `/loading?${planIdParameterName}=${planId}`,
      });
    }
  };

  const features = [
    {
      title: "Full financial forecast",
    },
    {
      title: "Business valuation",
    },

    {
      title: "Built in sanity checks",
    },
    {
      title: "Great for hands-on users",
    },
  ];

  return (
    <div className="flex flex-col justify-between p-8 xl:p-10 w-[410px] bg-gray-50 border border-gray-200 rounded-sm cursor-default text-left relative">
      <div className="mb-10">
        <ProductTitle
          productName={productName}
          productTagline={productTagline}
        />
        <ProductPrice
          currency={price.currency}
          unitAmount={price.unitAmount}
          interval={price.interval}
        />
        <ProductFeatures features={features} />
      </div>
      {activeSubscription && !disabled ? (
        <Button className="bg-green-800 hover:bg-green-800 text-white cursor-default">
          Active subscription
        </Button>
      ) : (
        <Button disabled={disabled} onClick={handleNewSubscriptionClick}>
          Get started
        </Button>
      )}
    </div>
  );
};

export default PricingCard;
