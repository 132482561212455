import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import ProductFeature from "./ProductFeature";

const ProductFeatures = ({ features }: any) => {
  return (
    <div className="mt-8 ">
      {/* <p className="font-bold text-left mb-2">Features:</p> */}

      <ul role="list" className="text-gray-600">
        {features &&
          features.map((feature: any) => (
            <ProductFeature key={feature.title} feature={feature} />
          ))}
      </ul>
    </div>
  );
};

export default ProductFeatures;
