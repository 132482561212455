export const stripePriceMapping = [
  {
    priceKey: "pro_annually",
    productId: "pro",
    productName: "Pro plan",
  },
  {
    priceKey: "pro_monthly",
    productId: "pro",
    productName: "Pro plan",
  },
  {
    priceKey: "valuation_annually",
    productId: "valuation",
    productName: "Valuation plan",
  },
]; // TODO: can also come from stripe directly

export function getProductId(priceKey: string) {
  const product = stripePriceMapping.find((item) => item.priceKey === priceKey);
  return product ? product.productId : null;
}
