import React from "react";

const ProductPrice = ({ currency, unitAmount, interval }: any) => {
  const amount = interval === "year" ? unitAmount / 100 / 12 : unitAmount / 100;
  const paymentInterval = interval === "year" ? "annually" : "monthly";

  const currencySymbol = currency === "usd" ? "$" : "undefined";

  const priceDisplay = `${currency} ${amount}`;

  return (
    <div className="mt-6 flex items-center gap-x-4 ">
      <div className="text-4xl font-semibold tracking-tight text-mainDark uppercase">
        {currency} {unitAmount / 100}
      </div>

      <div className="flex flex-col text-sm text-left  ">
        <span className="text-xs"> one year access</span>
      </div>
    </div>
  );
};

export default ProductPrice;
