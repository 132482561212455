import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-none text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-40 disabled:cursor-default",
  {
    variants: {
      variant: {
        default:
          "bg-main text-gray-800 font-medium shadow-sm hover:bg-mainLight",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "  border-2 border-gray-300 hover:bg-gray-200 text-gray-700 hover:text-black",
        secondary: "bg-gray-200  hover:bg-gray-150  ",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary    hover:text-mainLight",
      },
      size: {
        default: "h-10 px-4  py-0",
        sm: "h-9   px-3",
        lg: "h-11   px-8",
        icon: "h-10 w-10",
        link: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
