export const fetchUserData = async () => {
  const response = await fetch("/api/v1/user");
  if (!response.ok) throw new Error("Network response was not ok");
  return response.json();
};

export const fetchPricesData = async () => {
  const response = await fetch("/api/v1/stripe/prices");
  if (!response.ok) throw new Error("Network response was not ok");

  const data = await response.json();

  return data.data || {};
};

export const fetchUserSubscriptionData = async () => {
  const response = await fetch("/api/v1/stripe/userSubscription");
  if (!response.ok) throw new Error("Network response was not ok");

  const data = await response.json();

  return data.subscriptionData || {};
};

export const fetchCompanyData = async () => {
  const response = await fetch(`/api/v1/company`);

  if (!response.ok) {
    throw new Error("Failed to fetch company data");
  }
  const data = await response.json();

  return data.companyData || {};
};

export const fetchForecastData = async ({
  queryKey,
}: {
  queryKey: [string, { forecastId: string }];
}) => {
  const [, { forecastId }] = queryKey;
  const response = await fetch(`/api/v1/forecast/${forecastId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch forecast data");
  }

  const data = await response.json();

  return data.forecastData;
};

export const fetchActualsData = async ({
  queryKey,
}: {
  queryKey: [string, { forecastId: string }];
}) => {
  const [, { forecastId }] = queryKey;
  const response = await fetch(`/api/v1/forecast/${forecastId}/actuals`);
  if (!response.ok) {
    throw new Error("Failed to fetch actuals data");
  }

  const data = await response.json();

  return data.actualsData;
};
