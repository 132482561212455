"use client";

import React, { createContext, useContext, useState } from "react";
import { useParams } from "next/navigation";
import { PropsWithChildren } from "react";
import {
  fetchUserData,
  fetchCompanyData,
  fetchForecastData,
  fetchActualsData,
  fetchUserSubscriptionData,
  fetchPricesData,
} from "@utils/dataFetchers";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const UserInputContext = createContext({});
export const useUserInput = () => useContext(UserInputContext);

export const UserInputProvider = ({ children }: PropsWithChildren) => {
  const params = useParams();

  const { companyId: paramCompanyId, forecastId: paramForecastId } = params as {
    companyId: string;
    forecastId: string;
  };

  const [storedForecastId, setStoredForecastId] = useState<string | null>(null);

  const [forecastCase, setForecastCase] = useState({
    value: "base",
    label: "Base case",
  });

  const [showActuals, setShowActuals] = useState(false);

  const {
    data: userData,
    isLoading: userDataIsLoading,
    error: userDataError,
  } = useQuery({
    queryKey: ["userData"],
    queryFn: fetchUserData,
  });

  const {
    data: subscriptionData,
    isLoading: subscriptionDataIsLoading,
    error: subscriptionDataError,
  } = useQuery({
    queryKey: ["subscriptionData"],
    queryFn: fetchUserSubscriptionData,
  });

  const {
    data: pricesData,
    isLoading: pricesDataIsLoading,
    error: pricesDataError,
  } = useQuery({
    queryKey: ["priceData"],
    queryFn: fetchPricesData,
  });

  const {
    data: companyData,
    isLoading: companyDataIsLoading,
    error: companyDataError,
  } = useQuery({
    queryKey: ["companyData"],
    queryFn: fetchCompanyData,
  });

  const {
    data: forecastData,
    isLoading: forecastDataIsLoading,
    error: forecastDataError,
  } = useQuery({
    queryKey: [
      "forecastData",
      { forecastId: paramForecastId || storedForecastId || "" },
    ],
    queryFn: fetchForecastData,
    enabled: !!(paramForecastId || storedForecastId || ""), // Ensure the query is executed when either paramForecastId or storedForecastId is available
  });

  const {
    data: actualsData,
    isLoading: actualsDataIsLoading,
    error: actualsDataError,
  } = useQuery({
    queryKey: ["actualsData", { forecastId: paramForecastId }],
    queryFn: fetchActualsData,
    enabled: !!paramForecastId, // Ensure the query is only executed when paramForecastId is available
  });

  useEffect(() => {
    const forecastIdFromStorage = localStorage.getItem("forecastId");
    setStoredForecastId(forecastIdFromStorage);

    if (forecastData && forecastData._id) {
      localStorage.setItem("forecastId", forecastData._id);
    }
  }, [forecastData]);

  const dashboardUrl = storedForecastId
    ? `/forecast/${storedForecastId}/outputs/dashboard`
    : "/";

  const displayUnit = `${forecastData?.settings?.units?.currency}${forecastData?.settings?.units?.multiplier}`;

  const forecastCaseId = forecastCase.value;

  return (
    <UserInputContext.Provider
      value={{
        // financial data
        companyData,
        companyDataIsLoading,
        actualsData,
        actualsDataIsLoading,
        forecastData,
        forecastDataIsLoading,

        // user data
        userData: userData?.userData,
        userDataIsLoading,

        // stripe data
        prices: pricesData,
        pricesIsLoading: pricesDataIsLoading,
        subscriptionData,
        subscriptionDataIsLoading,

        // settings
        forecastCase,
        forecastCaseId,
        setForecastCase,
        displayUnit,
        storedForecastId,
        showActuals,
        setShowActuals,

        // other
        dashboardUrl,
      }}
    >
      {children}
    </UserInputContext.Provider>
  );
};
