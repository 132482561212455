"use client";
import { useState } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";
import { useSearchParams } from "next/navigation";
import { useUserInput } from "@providers/UserInputProvider";
import { useQueryClient } from "@tanstack/react-query";

const useSubscriptionHandlers = () => {
  const { prices, pricesIsLoading } = useUserInput() as any;
  const { data: session } = useSession();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleNewSubscription = async (productId: string) => {
    const redirectInParams = searchParams.get("redirect");

    let inputRedirectUrl: string;
    if (redirectInParams) {
      inputRedirectUrl = `/${redirectInParams}`;
    } else {
      inputRedirectUrl = `${pathname}`;
    }

    if (prices) {
      try {
        const response = await fetch("/api/v1/stripe/getSessionUrl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId: productId,
            email: session?.user?.email,
            pathname: inputRedirectUrl,
          }),
        });

        const stripeRedirectUrl = await response.json();

        router.push(stripeRedirectUrl);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCancelSubscription = async (subscriptionId: string) => {
    if (session?.user?.email) {
      setIsLoading(true); // Set loading state to true before starting the request
      try {
        const response = await fetch("/api/v1/stripe/cancel", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionId,
            email: session.user.email,
          }),
        });

        const result = await response.json();
        if (result.success) {
          queryClient.invalidateQueries({ queryKey: ["subscriptionData"] });
        } else {
          console.error("Failed to cancel subscription");
        }
      } catch (error) {
        console.error("Error cancelling subscription:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after the request completes
      }
    }
  };

  return {
    handleNewSubscription,
    handleCancelSubscription,
    isLoading, // Return the loading state so it can be used elsewhere
  };
};

export default useSubscriptionHandlers;
