import { cn } from "@lib/utils";

const Spinner = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        "w-6 h-6 border-2 border-gray-300 border-t-main rounded-full animate-spin",
        className
      )}
    ></div>
  );
};

export default Spinner;
