import Spinner from "./Spinner";

const LoadingScreen = () => {
  return (
    <div className="flex justify-center items-center min-h-[200px]">
      <Spinner />
    </div>
  );
};

export default LoadingScreen;
