import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import Link from "next/link";

const ProductFeature = ({ feature }: any) => {
  return (
    <li className="flex items-center gap-2 mb-2">
      <CheckIcon width={25} height={25} className="text-green-700" />
      {/* {feature.url ? (
        <Link href={feature.url}>
          <div className="">{feature.title}</div>
        </Link>
      ) : (
        <div className="">{feature.title}</div>
      )} */}

      <div className="">{feature.title}</div>
    </li>
  );
};

export default ProductFeature;
